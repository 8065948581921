import Swiper from 'swiper/bundle'
import 'swiper/css/bundle'

export const quickedLinkCardsSlider = () => {
    const components = document.querySelectorAll('.quicked_link_cards')
    if (!components.length) return

    components.forEach((component) => {
        const swiper = component.querySelector('.swiper')
        new Swiper(swiper, {
            slidesPerView: 1.2,
            spaceBetween: 16,

            breakpoints: {
                720: {
                    slidesPerView: 2.2,
                },
                1024: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                },
            },
        })
    })
}
