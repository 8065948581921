import Swiper from "swiper/bundle";
import "swiper/css/bundle";

export const sliderEventsSlider = () => {
    const sliderEventsSwiper = new Swiper(
        ".swiper.slider-events__swiper",
        {
            direction: "horizontal",
            loop: true,
            spaceBetween: 10,
            autoplay: {
                delay: 4000,
                disableOnInteraction: true,
            },
    
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
            },
    
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
        }
    );
}
