import EgoModal from "@egodesign/modal";

export const modalDetail = () => {
    const modalDetailElement = document.getElementById("modal-detail");
    const modalDetailBtn = document.getElementById("modal-detail-btn");

    if (modalDetailElement && modalDetailBtn) {
        const modalDetail = new EgoModal({
            element: modalDetailElement,
        });

        modalDetailBtn.addEventListener("click", () => {
            modalDetail.open();
        });
    }
}