import Swiper from "swiper/bundle";
import "swiper/css/bundle";

export const productSubcategoriesSlider = () => {
    const productSubcategoriesSwiper = new Swiper(
        ".swiper.cards-subcategories__swiper",
        {
            direction: "horizontal",
            loop: true,
            slidesPerView: 1.3,
            spaceBetween: 30,
            breakpoints: {
                400: {
                    slidesPerView: 1.6,
                },
                500: {
                    slidesPerView: 2.3,
                },
                600: {
                    slidesPerView: 2.7,
                },
                870: {
                    slidesPerView: 3.3,
                },
                1180: {
                    slidesPerView: 4,
                },
            },

            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
        }
    );
};
