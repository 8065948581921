export const accordionDropdown = () => {
    const acc = document.querySelectorAll('.accordion-dropdown-item .accordion-dropdown-item__summary')
    if (acc) {
        for (let i = 0; i < acc.length; i++) {
            acc[i].addEventListener('click', function () {
                this.parentNode.classList.toggle('active')
            })
        }
    }
}

export const seeMoreDropdownItems = () => {
    const dropdowns = document.querySelectorAll('.dropdown .accordion-dropdown-item')
    if (!dropdowns.length) return
    dropdowns.forEach((dropdown) => {
        const seeMore = dropdown.querySelector('.showMoreDropdownButton')
        if (seeMore) {
            seeMore.addEventListener('click', (e) => {
                showNextRows(dropdown)
                hideSeeMoreBtn(dropdown, e.target)
            })
        }
    })
}

function showNextRows(dropdown) {
    // Función para mostrar las próximas 10 filas ocultas
    const hiddenRows = dropdown.querySelectorAll('.dropdown-table__data tr[style*="display: none"]')

    if (!hiddenRows.length) return

    const numRowsToShow = 10

    for (let i = 0; i < numRowsToShow && i < hiddenRows.length; i++) {
        hiddenRows[i].style.display = '' // Muestra las próximas 10 filas ocultas
    }
}

const hideSeeMoreBtn = (dropdown, btn) => {
    // Función que se ejecuta cuando se hace clic en el botón "Mostrar más"
    const hiddenRows = dropdown.querySelectorAll('.dropdown-table__data tr[style*="display: none"]')

    if (hiddenRows.length === 0) {
        btn.style.display = 'none'
    }
}

export const simpleDropdown = () => {
    const dropdowns = document.querySelectorAll('.simple-dropdown')
    if (dropdowns) {
        dropdowns.forEach((dropdown) => {
            const dropdownBtn = dropdown.querySelector('.simple-dropdown__btn')
            dropdownBtn.addEventListener('click', () => {
                dropdown.classList.toggle('show')
            })
        })
    }
}