export const handleProductsPropertiesFilter = () => {
    const catList = document.querySelector('.filtered-equipments')
    if (!catList) return
    filteredProductsInCategoryList()

    // Init acc
    handleAccordions()
}

const handleAccordions = () => {
    const acc = document.querySelectorAll('.filtered-equipments .acc-chevron')
    if (acc.length) {
        for (let i = 0; i < acc.length; i++) {
            acc[i].addEventListener('click', function () {
                const item = this.closest('.filtered-equipments-item')
                item.classList.toggle('active')
            })
        }
    }
}

const filteredProductsInCategoryList = () => {
    const filteredProducts = [...document.querySelectorAll('.filtered-equipments-item')]
    // const filteredProducts = [...document.querySelectorAll('.filtered-products-category-list')]
    if (!filteredProducts.length) return

    filteredProducts.forEach((filteredProduct) => {
        const selects = [...filteredProduct.querySelectorAll('.btn-filter')]
        selects.forEach((select) => {
            select.addEventListener('change', (evt) => {
                const min = parseFloat(evt.target.options[select.selectedIndex].dataset.min)
                const max = parseFloat(evt.target.options[select.selectedIndex].dataset.max)
                const property = evt.target.options[select.selectedIndex].dataset.property

                const accordions = [...filteredProduct.querySelectorAll('.accordion-dropdown-item')]
                accordions.forEach((accordion) => {
                    const tables = [...accordion.querySelectorAll('.products-filtered-table')]
                    tables.forEach((table) => {
                        const tr = [...table.querySelectorAll('tbody tr')]

                        tr.forEach((trEl) => {
                            const td = [...trEl.querySelectorAll('.products-filtered-table__data__property-value')]
                            const filteredTd = td.filter((prop) => prop.dataset.property === property)

                            filteredTd.forEach((tdEl) => {
                                const productValue = parseFloat(tdEl.dataset.value)
                                const productProperty = tdEl.dataset.property

                                if (productProperty === property && productValue >= min && productValue <= max) {
                                    trEl.classList.remove(`hide-${productProperty}`) // Filtro restrictivo
                                } else if (productProperty === property && (productValue < min || productValue > max)) {
                                    trEl.classList.add(`hide-${productProperty}`)
                                }
                            })
                        })

                        const trHiddenEl = table.querySelectorAll('tbody tr[class^="hide-"]')

                        if (trHiddenEl.length === tr.length) {
                            table.classList.add('hide')
                        } else {
                            table.classList.remove('hide')
                        }

                        const accHiddenEl = accordion.querySelectorAll('.products-filtered-table.hide')

                        if (accHiddenEl.length === tables.length) {
                            accordion.classList.add('hide')
                            if (accordion.classList.contains('active')) {
                                accordion.classList.remove('active') // Cierra acordeón
                            }
                        } else {
                            accordion.classList.remove('hide')
                        }
                    })
                })
            })
        })
    })
}
