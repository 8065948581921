import Swiper from "swiper/bundle";
import "swiper/css/bundle";

export const sliderTitleTextSlider = () => {
    const sliderTitleTextSwiper = new Swiper(
        ".swiper.slider-title-text__swiper",
        {
            direction: "horizontal",
            loop: true,
    
            pagination: {
                el: ".swiper-pagination",
            },
    
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
        }
    ); 
}