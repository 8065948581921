import EgoModal from "@egodesign/modal";

export const imageFullView = () => {
    const imageElement1 = document.getElementById("modal-image1");
    const imageBtn1 = document.getElementById("btn-img-1");
    const imageElement2 = document.getElementById("modal-image2");
    const imageBtn2 = document.getElementById("btn-img-2");

    if (imageElement1 && imageBtn1 && imageElement2 && imageBtn2) {
        const modalForm1 = new EgoModal({
            element: imageElement1,
        });

        const modalForm2 = new EgoModal({
            element: imageElement2,
        });

        imageBtn1.addEventListener("click", () => {
            modalForm1.open();
        });

        imageBtn2.addEventListener("click", () => {
            modalForm2.open();
        });
    }
} 