import Swiper from 'swiper/bundle'
import 'swiper/css/bundle'
import { selectPromoGMT, viewPromoGTM } from '../modules/marketing/gtm'

export const heroHomeSlider = () => {

    let isSliderVisible = false;
    let hasViewedPromo = false;
    const sliderElement = document.querySelector('.swiper.hero-home__swiper');
    const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
            isSliderVisible = entry.isIntersecting;
            if (isSliderVisible && !hasViewedPromo) {
                // Si el slider es visible, ejecuta viewPromoGTM
                viewPromoGTM(swiperInstance);
                hasViewedPromo = true;
            }
        });
    }, { threshold: 0.5 });
    
    if (sliderElement) {
        observer.observe(sliderElement);
    }


    const swiperInstance = new Swiper('.swiper.hero-home__swiper', {
        speed: 600,
        autoplay: {
            delay: 6000,
            disableOnInteraction: false,
        },
        direction: 'horizontal',
        loop: true,
        sliderPerView: 1,
        pagination: {
            el: '.swiper-pagination',
            type: 'fraction',
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        on: {
            init: (swiper) => {
                selectPromoGMT(swiper)
            },
            slideChange: (swiper) => {
                if (isSliderVisible) viewPromoGTM(swiper)
            },
        },
    })
}
