import Swiper from "swiper/bundle";
import "swiper/css/bundle";

export const sliderWithImgSlider = () => {
    const sliderWithImagesSwiper = new Swiper(
        ".swiper.slider-with-images__swiper",
        {
            direction: "horizontal",
            loop: true,
            sliderPerView: 1,
            spaceBetween: 10,
            autoHeight: true,

            pagination: {
                el: ".swiper-pagination",
                type: "fraction",
            },
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
            breakpoints: {
                720: {
                    autoHeight: false,
                },
            },
        }
    );
};

export const sliderWithImgNavPag = () => {
    const imageSlide = document.querySelector(".slider-with-images__image");
    const navPag = document.querySelector(".slider-with-images__nav-pag");

    function setNavPagTop() {
        const height = imageSlide.clientHeight;
        const viewportWidth = window.innerWidth;

        if (imageSlide && navPag && viewportWidth < 1024) {
            navPag.style.top = `${height - 20}px`;
        } else {
            navPag.style.top = "0";
        }
    }

    if (imageSlide && navPag) {
        document.addEventListener("DOMContentLoaded", setNavPagTop);
        window.addEventListener("resize", setNavPagTop);
    }
};
