import Swiper from "swiper/bundle";
import "swiper/css/bundle";

export const sliderLandingsSlider = () => {
    const sliderLandingsSwiper = new Swiper(".swiper.slider-landings__swiper", {
        direction: "horizontal",
        loop: true,
        slidesPerView: 1.2,
        spaceBetween: 30,
        breakpoints: {
            500: {
                slidesPerView: 2.2,
            },
            900: {
                slidesPerView: 3,
            },
        },
    
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
    });
}