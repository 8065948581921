import Swiper from "swiper/bundle";
import "swiper/css/bundle";

export const textOnlySlider = () => {
    const textOnlySwiper = new Swiper(".swiper.text-only__swiper", {
        direction: "horizontal",
        loop: true,
        spaceBetween: 10,
    
        pagination: {
            el: ".swiper-pagination",
        },
    });
}