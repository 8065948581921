export const handleEquipmentsFilter = () => {
    const component = document.querySelector('.equipments-filter')
    if (!component) return

    // Selectores
    const familySelect = component.querySelector('#family-select')
    const actionSelect = component.querySelector('#action-select')

    if (familySelect) {
        familySelect.addEventListener('change', function (evt) {
            const selectedValue = evt.target.value
            reloadPageWithParams(selectedValue, 'p_family')
        })
    }

    if (actionSelect) {
        actionSelect.addEventListener('change', function (evt) {
            const selectedValue = evt.target.value
            reloadPageWithParams(selectedValue, 'p_action')
        })
    }

    // Buscador
    const searchBtn = component.querySelector('#equipments-filter-search-btn')
    const searchInput = component.querySelector('.equipments-filter__search-bar__input')
    if (searchBtn && searchInput) {
        searchBtn.addEventListener('click', function () {
            reloadPageWithParams(searchInput.value, 'p_term')
        })

        searchInput.value.length > 0 ? searchBtn.classList.remove('--disabled') : searchBtn.classList.add('--disabled')

        searchInput.addEventListener('input', function (evt) {
            evt.target.value.length > 0 ? searchBtn.classList.remove('--disabled') : searchBtn.classList.add('--disabled')
        })
    }

    // Init acc
    handleAccordions()
}

const reloadPageWithParams = (selectedValue, paramsKey) => {
    // URL
    const currentUrl = window.location.href
    const urlParams = new URLSearchParams(currentUrl.split('?')[1])

    // Verificar si el parámetro ya existe
    if (urlParams.has(paramsKey)) {
        // Si existe, sobrescribir su valor con el valor seleccionado
        urlParams.set(paramsKey, selectedValue)
    } else {
        // Si no existe, agregar el parámetro con el valor seleccionado
        urlParams.append(paramsKey, selectedValue)
    }

    // Crear una nueva URL con los parámetros actualizados
    const newUrl = window.location.origin + window.location.pathname + '?' + urlParams.toString()

    // Recargar a la nueva URL
    window.location.href = newUrl
}

const handleAccordions = () => {
    const acc = document.querySelectorAll('.categories-list-summary .acc-chevron')
    if (acc.length) {
        for (let i = 0; i < acc.length; i++) {
            acc[i].addEventListener('click', function () {
                const item = this.closest('.categories-list-item')
                item.classList.toggle('active')
            })
        }
    }
}


export const handleSelectedFilterOption = () => {
    const component = document.querySelector('.equipments-filter')
    if (!component) return

    // Selectores
    const familySelect = component.querySelector('#family-select')
    const actionSelect = component.querySelector('#action-select')

    const urlParams = new URLSearchParams(window.location.search)
    const familyParam = urlParams.get('p_family')
    const actionParam = urlParams.get('p_action')

    if (familyParam && familySelect) {
        familySelect.value = familyParam
    }

    if (actionParam && actionSelect) {
        actionSelect.value = actionParam
    }
}