export const threeTextImg = () => {

    const boxes = document.querySelectorAll('.three-text-img__video');
    const closeButton = document.querySelector('.three-text-img__modal__close');
    const modal = document.querySelector('.three-text-img__modal');
    const content = document.querySelector('.three-text-img__modal__content');
    const overlay = document.querySelector('.three-text-img__modal__overlay');

    boxes?.forEach(box => {
        box.addEventListener('click', () => { 
            const image = box.querySelector('.three-text-img__image');
            const videoUrl = image.getAttribute('data-video-url');
            openModal(videoUrl);
        });
    });
    
    const closeModal = () => {
        // NOTE: This is a workaround because video still playing when the modal is closed
        setTimeout(() => {
            content.innerHTML = '';
        }, 300);
        modal.classList.remove('is_open');
    }

    const openModal = (videoUrl) => {
        modal.classList.add('is_open')
        content.innerHTML = `<iframe width="560" height="315" src=${videoUrl} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`;
    } 

    closeButton?.addEventListener('click', () => {
        closeModal();
    });
    overlay?.addEventListener('click', () => {
        closeModal();
    });
}