import Swiper from "swiper/bundle";
import "swiper/css/bundle";

export const sliderThreeSlidesSlider = () => {
    const sliderThreeSlidesSwiper = new Swiper(
        ".swiper.slider-three-slides__swiper",
        {
            direction: "horizontal",
            loop: true,
            slidesPerView: 1,
            spaceBetween: 30,
    
            breakpoints: {
                500: {
                    slidesPerView: 2,
                },
                900: {
                    slidesPerView: 3,
                },
            },
    
            pagination: {
                el: ".swiper-pagination",
            },
    
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
        }
    );
}