export const accordionDropdownImages = () => {
    const acc = document.querySelectorAll(".accordion-dropdown-images");
    if (acc) {
        for (let i = 0; i < acc.length; i++) {
            acc[i].addEventListener("click", function () {
                this.classList.toggle("active");

                const panel = this.nextElementSibling;
                // const panelScrollHeight = panel.scrollHeight + "px";

                // console.log(panelScrollHeight);

                const plus = this.querySelector(".toggle-up");
                const minus = this.querySelector(".toggle-down");

                if (panel.style.display === "flex") {
                    panel.style.display = "none";
                    plus.style.display = "none";
                    minus.style.display = "flex";
                } else {
                    panel.style.display = "flex";
                    plus.style.display = "flex";
                    minus.style.display = "none";
                }
            });
        }
    }
}