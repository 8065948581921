import Swiper from "swiper/bundle";
import "swiper/css/bundle";

export const textImgVerticalSlider = () => {
    const textImgVerticalSwiper = new Swiper(
        ".swiper.text-img-vertical__container__swiper",
        {
            direction: "horizontal",
            loop: true,
    
            pagination: {
                el: ".swiper-pagination",
            },
        }
    );
}