import Swiper from "swiper/bundle";
import "swiper/css/bundle";

export const bulletHighlightedSlider = () => {
    const swiper = document.querySelector(".swiper.bullet-highlighted__mobile__swiper");
    if(!swiper) return;
    const bulletHighlightedSwiper = new Swiper(swiper, {
        direction: "horizontal",
        loop: true,
        slidesPerView: 1,
    
        pagination: {
            el: ".swiper-pagination",
        },
    });
}