import EgoForm from '@egodesign/form'
import { getCSRFTokenValue, handleModalErrorRetry, handleRetrySubmitButton, validateRecaptcha } from '../modules/form-helpers'
import { webinarFormGTM } from '../modules/marketing/gtm'

export const formWebinars = () => {
    const formWebinarsElement = document.querySelector('#webinarsForm')

    if (formWebinarsElement) {
        const successMsg = formWebinarsElement.querySelector('.form-webinars__success-msg')
        const btnSubmit = formWebinarsElement.querySelector("button[type='submit']")
        const modalErrorNode = document.querySelector('#modal-webinar-error')

        const modalErrorInstance = handleModalErrorRetry(modalErrorNode, btnSubmit)

        const csrf = getCSRFTokenValue(formWebinarsElement)
        const webinarFormInstance = new EgoForm({
            element: formWebinarsElement,
            submitType: 'fetch',
            requestHeaders: {
                'X-CSRFToken': csrf,
            },
            onSuccess: () => {
                // Ocultamos botón submit
                btnSubmit.style.display = 'none'
                // Mostramos mensaje de éxito
                successMsg.style.display = 'flex'
                // Cerramos el modal de error
                if (modalErrorNode.classList.contains('--open')) modalErrorInstance.close()

                webinarFormGTM(formWebinarsElement, 'success')
            },
            onValidationError: (errorFieldsNames) => {
                if (errorFieldsNames.length) webinarFormGTM(formWebinarsElement, 'error', errorFieldsNames)
            },
            onError: () => {
                handleRetrySubmitButton(modalErrorNode)

                // Abrimos el modal si aún no está abierto
                if (!modalErrorNode.classList.contains('--open')) modalErrorInstance.open()

                webinarFormGTM(formWebinarsElement, 'error')
            },
            onBeforeSubmit: () => validateRecaptcha(webinarFormInstance, csrf),
            preventSubmit: true,
        })
    }
}
