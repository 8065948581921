import Swiper from "swiper/bundle";
import "swiper/css/bundle";

export const cardsFeaturesSlider = () => {
    const cardsFeaturesSwiper = new Swiper(".swiper.cards-features__swiper", {
        direction: "horizontal",
        loop: true,
    
        pagination: {
            el: ".swiper-pagination",
        },
    });
}