export const equipments = () => {
    const equipmentSection = document.querySelector(".equipments__component");
    const equipmentSectionMobile = document.querySelector(".equipments__component-mobile");
    const equipmentHeader = document.querySelector(".header__nav .equipment");
    const btnHeaderMobile = document.querySelector(".header__grid .header__menu-button");
    const items = document.querySelectorAll(".equipments__component .equipment__item");
    let activeItem = null;

    if (items) {
        items.forEach((item) => {
            item.addEventListener("click", () => {
                if (item !== activeItem) {
                    if (activeItem) {
                        activeItem.classList.remove("--active");
                    }
                    item.classList.add("--active");
                    activeItem = item;

                    const centerContent = document.querySelectorAll(
                        ".equipment__center > div"
                    );
                    const contentKey = item.getAttribute("data-content");
                    for (const content of centerContent) {
                        if (content.dataset.content === contentKey) {
                            content.style.opacity = "1";
                            setTimeout(() => {
                                content.style.display = "block";
                            }, 250);
                        } else {
                            content.style.opacity = "0";
                            setTimeout(() => {
                                content.style.display = "none";
                            }, 250);
                        }
                    }
                } else {
                    item.classList.remove("--active");
                    activeItem = null;

                    const centerContent = document.querySelectorAll(
                        ".equipment__center > div"
                    );
                    for (const content of centerContent) {
                        content.style.opacity = "0";
                        setTimeout(() => {
                            content.style.display = "none";
                        }, 250);
                    }
                }

                const hasActive = [...items].some((item) =>
                    item.classList.contains("--active")
                );
                const rightContent = document.querySelector(
                    ".equipment__right"
                );
                if (hasActive) {
                    setTimeout(() => {
                        rightContent.style.opacity = "1";
                    }, 250);
                } else {
                    rightContent.style.opacity = "0";
                }
            });
        });
    }

    if (equipmentSection) {
        equipmentHeader.style.pointerEvents = "none";
    }

    if (equipmentSectionMobile) {
        btnHeaderMobile.style.display = "none";
    }
}