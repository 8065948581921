export const handlePropsFilter = () => {
    const menu = document.querySelector('.main-header')
    const propertiesFilterComponent = document.querySelector('.properties-filter')

    if (!menu || !propertiesFilterComponent) return

    const selects = propertiesFilterComponent.querySelectorAll('.properties-filter__prop select')
    const submitBtn = propertiesFilterComponent.querySelector('#properties-filter-form .properties-filter__search-btn')

    let lastScrollTop = 0
    let initComponentTop = propertiesFilterComponent.offsetTop

    window.addEventListener('scroll', function () {
        const menuHeight = menu.offsetHeight
        const componentTop = propertiesFilterComponent.offsetTop

        const st = window.scrollY || document.documentElement.scrollTop

        if (st > lastScrollTop) {
            // Scrolleando hacia abajo
            if (st >= componentTop - menuHeight) {
                propertiesFilterComponent.style.top = menuHeight - 1 + 'px'
            }
        } else {
            // Scrolleando hacia arriba
            if (st <= initComponentTop - menuHeight) {
                propertiesFilterComponent.style.top = '0'
            }
        }

        lastScrollTop = st <= 0 ? 0 : st // Evitar valores negativos en el caso de rebote al principio
    })

    preselectedOptions(propertiesFilterComponent)
    setQueriesString(selects, submitBtn)
}

const setQueriesString = (selects, submitBtn) => {
    submitBtn.addEventListener('click', (e) => {
        e.preventDefault()
        let params = []
        let paramsToRemove = []

        if (selects.length) {
            selects.forEach((select, index) => {
                if (select.value) {
                    const property = select.name
                    const min = select.options[select.selectedIndex].dataset.min
                    const max = select.options[select.selectedIndex].dataset.max
                    params.push({
                        key: `prop${index + 1}`,
                        name: property,
                        min,
                        max,
                    })
                } else {
                    paramsToRemove.push({
                        key: `prop${index + 1}`,
                    })
                }
            })

            reloadPageWithParams(params, paramsToRemove)
        }
    })
}

const reloadPageWithParams = (params, paramsToRemove) => {
    // URL
    const currentUrl = window.location.href
    const urlParams = new URLSearchParams(currentUrl.split('?')[1])

    // Setea parámetros
    params.forEach((param) => {
        urlParams.set(`${param.key}_name`, param.name)
        urlParams.set(`${param.key}_min`, param.min)
        urlParams.set(`${param.key}_max`, param.max)
    })

    // Quita parámetros
    paramsToRemove.forEach((param) => {
        urlParams.delete(`${param.key}_name`)
        urlParams.delete(`${param.key}_min`)
        urlParams.delete(`${param.key}_max`)
    })

    // Crear una nueva URL con los parámetros actualizados
    const newUrl = window.location.origin + window.location.pathname + '?' + urlParams.toString()

    // Recargar a la nueva URL
    window.location.href = newUrl
}

// Setea la opción a partir de la query
const preselectedOptions = (component) => {
    const url = new URL(window.location.href)

    // Nodos
    const select1 = component.querySelector('#prop1')
    const select2 = component.querySelector('#prop2')

    // Obtiene el valor de los parámetros min y max
    const prop1max = url.searchParams.get('prop1_max')
    const prop1min = url.searchParams.get('prop1_min')
    const prop2max = url.searchParams.get('prop2_max')
    const prop2min = url.searchParams.get('prop2_min')

    // Selecciona opt
    if (prop1max && prop1min) {
        // Itera sobre las opciones para encontrar la coincidencia
        Array.from(select1.options).forEach((option) => {
            if (option.value === `${prop1min}-${prop1max}`) {
                // Establece la opción como seleccionada
                option.selected = true
            }
        })
    }

    if (prop2max && prop2min) {
        Array.from(select2.options).forEach((option) => {
            if (option.value === `${prop2min}-${prop2max}`) {
                // Establece la opción como seleccionada
                option.selected = true
            }
        })
    }

    // Scroll hacia el listado
    if ((prop1max && prop1min) || (prop2max && prop2min)) {
        const filteredProducts = document.querySelector('.products-filtered')

        // Abrir acordeón principal
        const mainAcc = document.querySelector('.main-acc')
        mainAcc.classList.add('active')

        // Llevar el scroll hacia el componente
        const filterBarHeight = component.getBoundingClientRect().height
        const offset = filterBarHeight * 2 + 50
        const bodyRect = document.body.getBoundingClientRect().top
        const elementRect = filteredProducts.getBoundingClientRect().top
        const elementPosition = elementRect - bodyRect
        const offsetPosition = elementPosition - offset

        window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth',
        })
    }
}
